import store from '@/store'
import router from '@/router'

export default {
    state: {
        odaYonetimi: [],
        odaTurleri: [],
        userList: [],
        departman: [],
        acenta: [],
    },
    mutations: {
        //! Acenta
        acentaSave(state, list) {
            state.acenta = list
        },
        clearAcenta(state) {
            state.acenta = []
        },
        //! Departman
        departmanSave(state, list) {
            state.departman = list
        },
        //! User List
        UserListSave(state, list) {
            state.userList = list
        },
        clearUserList(state) {
            state.userList = []
        },
        //! Oda Turleri
        odaTurleriSave(state, list) {
            state.odaTurleri = list
        },
        clearOdaTurleri(state) {
            state.odaTurleri = []
        },
        //! Oda Yönetim
        odaYonetimSave(state, list) {
            state.odaYonetimi = list
        },
        clearOdaYonetimi(state) {
            state.odaYonetimi = []
        },
    },
    actions: {
        //! Acenta
        acentaVeri({
                       commit,
                       state
                   }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'acenta',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setToken', res.userToken)
                        commit('acentaSave', res)
                    },
                    error(err) {
                        reject(err)
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            })
        },
        acentaRemove({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'acenta',
                acentaSil: authData.acentaID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('acentaSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        acentaSave({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'acenta',
                acenta: authData.acentaName,
                kod: authData.acentaKod,
                renk: authData.renk,
                islem: 'acentaKaydi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('acentaSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        acentaUpdate({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'acenta',
                acenta: authData.acentaName,
                kod: authData.acentaKod,
                renk: authData.renk,
                acentaID: authData.acentaID,
                islem: 'acentaDuzenle',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('acentaSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        //! Departman
        departmanUpdate({
                            commit,
                            state
                        }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'departman',
                departmanAdi: authData.departmanName || '',
                departmanID: authData.upID,
                // Edit: authData.id,
                islem: 'departmanDuzenle',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('departmanSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        departmanSave({
                          commit,
                          state
                      }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'departman',
                islem: 'departmanKaydi',
                departmanAdi: authData.departmanName || '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('departmanSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        departmanRemove({
                            commit,
                            state
                        }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'departman',
                departmanSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('departmanSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        departman({
                      commit,
                      state
                  }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'departman',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setToken', res.userToken)
                        commit('departmanSave', res)
                    },
                    error(err) {
                        reject(err)
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            })
        },
        //! User List
        userPwdChange({
                          commit,
                          state,
                          dispatch
                      }, authData) {
            const firmaKodu = localStorage.getItem('otelFirmaKodu')
            const userName = localStorage.getItem('otelUserName')
            const pwd = authData.sifreDegis
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'userList',
                userID: authData.userID,
                sifreDegis: authData.sifreDegis,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                        dispatch('logIn', {
                            firmaKodu,
                            user: userName,
                            pwd,
                        })
                        commit('UserListSave', res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userUpdate({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'userList',
                level: authData.tur,
                isim: authData.isim,
                tel: authData.tel,
                ePosta: authData.ePosta,
                editUser: authData.userID,
                modul: authData.modul,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('UserListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        newUser({
                    commit,
                    state
                }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'userList',
                level: authData.tur,
                userName: authData.user,
                pwd: authData.pwd,
                isim: authData.isim,
                tel: authData.tel,
                ePosta: authData.ePosta,
                addUser: 1,
                modul: authData.modul,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('UserListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userSil({
                    commit,
                    state
                }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'userList',
                delUser: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('UserListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userStatusChange({
                             commit,
                             state
                         }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'userList',
                userPasif: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                        commit('UserListSave', res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userList({
                     commit,
                     state
                 }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'userList',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setToken', res.userToken)
                        commit('UserListSave', res)
                    },
                    error(err) {
                        reject(err)
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            })
        },
        //! Oda Türleri
        odaTurleri({
                       commit,
                       state
                   }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaTurleri',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setToken', res.userToken)
                        commit('odaTurleriSave', res)
                    },
                    error(err) {
                        reject(err)
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            })
        },
        odaTuruSil({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaTurleri',
                odaTurSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('odaTurleriSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaTuruKaydi({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaTurleri',
                adi: authData.adi,
                tip: authData.tip,
                islem: 'turKayit',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('odaTurleriSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        // odaTurUpdate({commit, state}, authData) {
        //     const data = {
        //         crm_token: localStorage.getItem('otelUserToken'),
        //         serviceName: 'otel',
        //         methodName: 'odaTurleri',
        //         adi: authData.adi,
        //         tip: authData.tip,
        //         tipID: authData.tipID,
        //         islem: 'edit',
        //     }
        //     return new Promise((resolve, reject) => {
        //         $.ajax({
        //             type: 'POST',
        //             url: store.state.POST_URL,
        //             data,
        //             success(res) {
        //                 commit('setToken', res.userToken)
        //                 commit('odaTurleriSave', res)
        //                 resolve(res)
        //             },
        //             error(err) {
        //                 if (err.status == 503) {
        //                     router.push('/expariedLogin')
        //                 }
        //                 reject(err)
        //             },
        //             dataType: 'json',
        //         })
        //     })
        // },
        //! Oda Yönetim
        odaUpdate({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaYonetimi',
                odaKodu: authData.odaKodu,
                kat: authData.kat,
                yatak: authData.yatak,
                eYatak: authData.eYatak,
                tel: authData.tel,
                tip: authData.tip,
                paylasimli: authData.paylasimli,
                renk: authData.renk,
                online: authData.online,
                odaID: authData.odaID,
                islem: 'edit',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('odaYonetimSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaKaydi({
                     commit,
                     state
                 }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaYonetimi',
                odaKodu: authData.odaKodu,
                kat: authData.kat,
                yatak: authData.yatak,
                eYatak: authData.eYatak,
                tel: authData.tel,
                tip: authData.tip,
                paylasimli: authData.paylasimli,
                renk: authData.renk,
                online: authData.online,
                islem: 'odaKayit',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('odaYonetimSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaOnlineDurumChange({
                                 commit,
                                 state
                             }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaYonetimi',
                online: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                        commit('odaYonetimSave', res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaDurumChange({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaYonetimi',
                paylasimli: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                        commit('odaYonetimSave', res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaYonetim({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaYonetimi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setToken', res.userToken)
                        commit('odaYonetimSave', res)
                    },
                    error(err) {
                        reject(err)
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            })
        },
        odaSil({
                   commit,
                   state
               }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaYonetimi',
                odaSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('odaYonetimSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
    getters: {
        //! Acenta
        acentaList(state) {
            if (state.acenta) {
                return state.acenta.liste
            }
        },
        //! Departman
        departmanList(state) {
            if (state.departman) {
                if (state.departman.liste) {
                    return state.departman.liste
                }
            }
        },
        //! User List
        userList(state) {
            if (state.userList) {
                return state.userList.userList
            }
        },
        //! Oda Türleri
        odaTurleriList(state) {
            if (state.odaTurleri) {
                return state.odaTurleri.odaTurleri
            }
        },
        //! Oda Yönetim
        odaList(state) {
            if (state.odaYonetimi) {
                return state.odaYonetimi.odaListesi
            }
        },
        odaTurleri(state) {
            if (state.odaYonetimi) {
                return state.odaYonetimi.odaTurleri
            }
        },
        odaParams: state => COLOR_CODE => state.odaYonetimi.params.odaRenk[COLOR_CODE],
    },
}
