import router from '@/router'
import store from '@/store'

export default {
    state: {
        faturaList: [],
        cariList: [],
        cariDetayList: [],
        cariEkstre: [],
    },
    mutations: {
        //! CARİ EKSTRE
        cariEkstreSave(state, list) {
            state.cariEkstre = list
        },
        clearCariEkstre(state) {
            state.cariEkstre = []
        },
        //! CARİ DETAY
        cariDetayListSave(state, list) {
            state.cariDetayList = list.data
        },
        clearCariDetayList(state) {
            state.cariDetayList = []
        },
        //! CARİ LİSTE
        cariListSave(state, list) {
            delete list.userToken
            state.cariList = list
        },
        clearCariList(state) {
            state.cariList = []
        },
        //! FATURA KAYITLARI
        faturaListSave(state, list) {
            state.faturaList = list
        },
        clearFaturaList(state) {
            state.faturaList = []
        },
    },
    actions: {
        //! CARİ EKSTRE
        cariEkstre({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'cariEkstre',
                carikartID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setToken', res.userToken)
                        commit('cariEkstreSave', res)
                    },
                    error(err) {
                        reject(err)
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            })
        },
        //! CARİ DETAY
        devirSave({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'cariDetay',
                islem: 'devirKaydi',
                vergiNumarasi: authData.vergiNumarasi,
                carikartID: authData.id,
                tutar: authData.tutar,
                hareket: authData.hareket,
                islemNotu: authData.islemNotu,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('cariDetayListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        tahsilatRemove({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'cariDetay',
                islem: 'kasaSil',
                kasaID: authData.kasaID,
                carikartID: authData.carikartID,
                vergiNumarasi: authData.vergiNumarasi,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('cariDetayListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        tahsilatSave({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'cariDetay',
                islem: 'tahsilatKayit',
                vergiNumarasi: authData.vergiNumarasi,
                carikartID: authData.id,
                girisTuru: authData.girisTuru,
                tutar: authData.tutar,
                fisNo: authData.fisNo,
                aciklama: authData.aciklama,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('cariDetayListSave', res)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariDetay({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                vergiNumarasi: authData.vergiNumarasi,
                methodName: 'cariDetay',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('cariDetayListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariUpdate({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'cariDetay',
                islem: 'cariDuzenle',
                vergiNumarasi: authData.vergiNumarasi || ' ',
                carikartID: authData.carikartID || ' ',
                adi: authData.adi || ' ',
                soyAdi: authData.soyAdi || ' ',
                adres: authData.adres || ' ',
                ePosta: authData.ePosta || ' ',
                gsm: authData.gsm || ' ',
                telefon: authData.telefon || ' ',
                faturaUnvan: authData.faturaUnvan || ' ',
                vergiDairesi: authData.vergiDairesi || ' ',
                // Todo KULLANICI DEGER GIRMEZ ISE BOSLUK GIDICEK (backend in ayibi)
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('cariDetayListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariRemove({
                       commit,
                       state,
                       dispatch
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'cariSil',
                vergiNumarasi: authData.vergiNumarasi,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        //! CARİ LİSTE
        cariList({
                     commit,
                     state
                 }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'cariListe',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('cariListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariListSearch({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'cariListeSearch',
                baslangic: authData.baslangic,
                uzunluk: authData.uzunluk,
                searchKey: authData.searchKey,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        state.cariList = res.cariList
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        cariSave({
                     commit,
                     state
                 }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'carikart',
                methodName: 'yeniCarikart',
                adi: authData.adi,
                soyAdi: authData.soyAdi,
                adres: authData.adres,
                ePosta: authData.posta,
                gsm: authData.gsm,
                telefon: authData.tel,
                faturaUnvan: authData.firmaUnvan,
                vergiNumarasi: authData.vergiNo,
                vergiDairesi: authData.vergiDairesi,
                islem: 'yeniCarikart',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('cariListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        //! FATURA KAYITLARI
        faturaList({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'fatura',
                methodName: 'faturaListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('faturaListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        faturaListFiltre({
                             commit,
                             state
                         }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'fatura',
                methodName: 'faturaListesi',
                baslangic: authData.baslangic,
                bitis: authData.bitis,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('faturaListSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(er)
                    },
                    dataType: 'json',
                })
            })
        },
        faturaDetay({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'fatura',
                methodName: 'faturaDetay',
                faturaID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
    },
    getters: {
        //! CARİ EKSTRE
        cariEkstreReturn(state) {
            if (state.cariEkstre) {
                return state.cariEkstre
            }
        },
        //! CARİ DETAY
        cariDetayListReturn(state) {
            if (state.cariDetayList) {
                return state.cariDetayList
            }
        },
        cariDetayEkstre(state) {
            if (state.cariDetayList.cariEkstre) {
                return state.cariDetayList.cariEkstre.ekstre
            }
        },
        //! CARİ KAYITLARI
        cariListReturn(state) {
            if (state.cariList) {
                return state.cariList
            }
        },
        //! FATURA KAYITLARI
        faturaListReturn(state) {
            if (state.faturaList) {
                return state.faturaList
            }
        },
    },
}
