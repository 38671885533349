import store from '@/store'

export default {
    state: {
        params: [],
    },
    mutations: {
        clearDeparman(state) {
            state.deparmanList = []
        },
        setToken(state, token) {
            if (token) {
                localStorage.setItem('otelUserToken', token)
            }
        },
        setWorkDay(state, day) {
            state.workDay = day
        },
        setRoom(state, room) {
            state.room = room
        },
        setParams(state, param) {
            state.params = param
        },

    },
    actions: {
        normalFaturaOlustur({
                                commit,
                                state
                            }, authData) {
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data: authData.data,
                    success(res) {
                        resolve(res)
                        // if (res.result.status == 200) {
                        //     resolve(res)
                        // } else {
                        //     reject(res)
                        // }
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        hizliFaturaOlustur({
                               commit,
                               state
                           }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'dashBoard',
                methodName: 'hizliFatura',
                faturaUnvan: authData.faturaUnvan,
                ePosta: authData.ePosta,
                adi: authData.adi,
                soyAdi: authData.soyAdi,
                vergiDairesi: authData.vergiDairesi,
                vergiNumarasi: authData.vergiNumarasi,
                faturaAdres: authData.faturaAdres,
                faturaIl: authData.faturaIl,
                faturaIlce: authData.faturaIlce,
                urunAdi: authData.urunAdi,
                kdvOrani: authData.kdvOrani,
                toplamTutar: authData.toplamTutar.toString()
                    .replace(/,/g, '.'),
                faturaNotu: authData.faturaNotu,
                siparisNo: authData.siparisNo,
                konaklamaVergisi: authData.konaklamaVergisi,
                telefon: authData.telefon,
                hguestID: authData.hguestID || '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        // if (res.result.status == 200) {
                        //     resolve(res)
                        // } else {
                        //     reject(res)
                        // }
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
