import jwt_decode from 'jwt-decode'
import router from '@/router'
import store from '@/store'

export default {
    state: {
        userToken: '',
        userData: [],
        workDay: [],
        room: [],
        acentaList: [],
        params: [],
        bildirim: [],
        isVardiya: false,
        level: 0,
        vardiya: [],
        tarife: [],
        selectedTarife: '',
        modul: '',
    },
    mutations: {
        //! TARİFE
        setTarife(state, list) {
            state.tarife = list
            localStorage.setItem('otelFirstTarifeID', Object.values(list)[0]?.tarifeID)
        },
        clearTarife(state) {
            state.tarife = []
        }, //! VARDİYA
        setVardiya(state, list) {
            state.vardiya = list
        }, //! BILDIRIM
        setBildirim(state, list) {
            state.bildirim = list
        }, //! !
        setToken(state, token) {
            state.userToken = token
            localStorage.setItem('otelUserToken', token)
        },
        setAcenta(state, acenta) {
            state.acentaList = acenta
        },
        setUserData(state, jwt) {
            state.level = jwt.level
            state.userData = jwt
            localStorage.setItem('otelUserName', jwt.userName)
            localStorage.setItem('otelFirmaKodu', jwt.firmaKodu)
            localStorage.setItem('otelFullName', jwt.FullName)
        },
        setWorkDay(state, day) {
            if (day) {
                state.workDay = day
                store.state.dateDefaultGlobal = day
                localStorage.setItem('otelWorkDay', day)
            }
        },
        setRoom(state, room) {
            state.room = room
        },
        setParams(state, param) {
            localStorage.setItem('otelTema', param.tema)
            localStorage.setItem('otelListType', param.tema)
            state.params = param
        },
        clearToken(state) {
            state.userToken = ''
            localStorage.removeItem('otelUserToken')
        },
        clearRoom(state) {
            state.room = []
        },
        clearUserData(state) {
            state.userData = []
        },
        clearWorkDay(state) {
            state.workDay = []
        },
    },
    getters: {
        //! BİLDİRİMLER
        notificationSettings: () => val => {
            if (val?.result) {
                const cevap = JSON.stringify(val.result.errMsg)
                const reply = cevap.slice(1, -1)
                return reply
            }
        }, //! TARİFE
        tarife(state) {
            if (state.tarife) {
                return state.tarife
            }
        }, //! LEVEL
        level(state) {
            if (state.level) {
                return state.level
            }
        }, //! VARDİYA
        vardiya(state) {
            if (state.vardiya) {
                return state.vardiya
            }
        },
        returnVardiya(state) {
            if (state.isVardiya) {
                return state.isVardiya
            }
        }, //! ! BİLDİRİMLER
        returnBildirim(state) {
            if (state.bildirim) {
                return state.bildirim
            }
        }, //! !!
        fullName(state) {
            if (state.userData) {
                return state.userData.FullName
            }
        },
        paramsReturn(state) {
            if (state.params) {
                return state.params
            }
        },
        headerBg(state) {
            if (state.params.colors) {
                return state.params.colors.headerBg
            }
        },
        footerBg(state) {
            if (state.params.colors) {
                return state.params.colors.footerBg
            }
        },
        cardTitle(state) {
            if (state.params.colors) {
                return state.params.colors.cardTitle
            }
        },
        cardTitleButton(state) {
            if (state.params.colors) {
                return state.params.colors.cardTitleButton
            }
        },
        tableTitle(state) {
            if (state.params.colors) {
                return state.params.colors.tableTitle
            }
        },
        bgSuccess(state) {
            if (state.params.colors) {
                return state.params.colors.bgSuccess
            }
        },
        bgInfo(state) {
            if (state.params.colors) {
                return state.params.colors.bgInfo
            }
        },
        bgDanger(state) {
            if (state.params.colors) {
                return state.params.colors.bgDanger
            }
        },
        bgWarning(state) {
            if (state.params.colors) {
                return state.params.colors.bgWarning
            }
        },
        bgPrimary(state) {
            if (state.params.colors) {
                return state.params.colors.bgPrimary
            }
        },
        kirliOda(state) {
            if (state.params.colors) {
                return state.params.colors.odaDurum.kirliOda
            }
        },
        arizaliOda(state) {
            if (state.params.colors) {
                return state.params.colors.odaDurum.arizaliOda
            }
        },
        kullanimDisi(state) {
            if (state.params.colors) {
                return state.params.colors.odaDurum.kullanimDisi
            }
        },
        odaKapatma(state) {
            if (state.params.colors) {
                return state.params.colors.odaDurum.odaKapatma
            }
        },
        fullOda(state) {
            if (state.params.colors) {
                return state.params.colors.odaDurum.fullOda
            }
        },
        kullanimDisi(state) {
            if (state.params.colors) {
                return state.params.colors.odaDurum.kullanimDisi
            }
        },
        odalarReturn(state) {
            return state.room.roomList
        },
        kullaniciReturn(state) {
            return state.userData
        },
        isAuthenticated(state) {
            return state.userToken !== ''
        },
    },
    actions: {
        tokenTimeControl({
                             commit,
                             state,
                             dispatch
                         }, authData) {
            if (authData.status == 503) {
                router.push('/expariedLogin')
            }
        }, //! TARİFE
        tarifeTransfer({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'tarifeYonetimi',
                islem: 'iceAktar',
                yeniTarife: authData.yeniTarife,
                eskiTarife: authData.eskiTarife,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setTarife', res.params.tarifeList)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        tarife({
                   commit,
                   state
               }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'tarifeYonetimi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setTarife', res.liste)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            })
        },
        setTarife({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'tarifeYonetimi',
                islem: 'tarifeKaydi',
                tarifeAdi: authData.departmanName || '',
                tarifeTuru: authData.tarifeTuru,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setTarife', res.liste)
                        resolve(res)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        tarifeRemove({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'tarifeYonetimi',
                tarifeSil: authData.tarifeID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setTarife', res.liste)
                        resolve(res)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        tarifeUpdate({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'tarifeYonetimi',
                tarifeAdi: authData.departmanName || '',
                tarifeID: authData.tarifeID,
                islem: 'tarifeDuzenle',
                tarifeTuru: authData.tarifeTuru,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setTarife', res.liste)
                        resolve(res)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, //! !!!
        temaUpdate({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'dashBoard',
                methodName: 'userProfilUpdate',
                tema: authData.tema,
                listType: authData.listType,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        sifreUpdate({
                        commit,
                        state,
                        dispatch
                    }, authData) {
            const firmaKodu = localStorage.getItem('otelFirmaKodu')
            const userName = localStorage.getItem('otelUserName')
            const { pwd } = authData
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'dashBoard',
                methodName: 'sifreDegis',
                pwd: authData.pwd,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                        dispatch('logIn', {
                            firmaKodu,
                            user: userName,
                            pwd,
                        })
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        takvimGuncellestir({
                               commit,
                               state
                           }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'dashBoard',
                methodName: 'calismaGunuGuncelle',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('setWorkDay', res.calismaGunu)
                        commit('setRoom', res)
                        commit('setBildirim', res.bildirim)
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        selectDate({
                       commit,
                       state
                   }, authData) {
            if (authData.dateDefault) {
                commit('clearRoom')
                const data = {
                    serviceName: 'dashBoard',
                    methodName: 'dashboardTarih',
                    tarih: authData.dateDefault,
                    crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                }
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setWorkDay', res.calismaGunu.tarih)
                            commit('setRoom', res)
                            commit('setParams', res.params)
                            commit('setAcenta', res.acentaList)
                            commit('setBildirim', res.bildirim)
                        }
                        return false
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            }
        },
        dateBack({
                     commit,
                     state
                 }, authData) {
            commit('clearRoom')
            const today = new Date(state.workDay)
            const tarih = today.setDate(today.getDate() - 1)
            const gidenTarih = new Date(tarih).toLocaleDateString('en-US')
            const yeniDate = new Date(gidenTarih)
            const yil = yeniDate.getFullYear()
            const ay = String(yeniDate.getMonth() + 1)
                .padStart(2, '0')
            const gun = String(yeniDate.getDate())
                .padStart(2, '0')
            const readyTarih = [yil, ay, gun].join('-')
            store.state.dateDefaultGlobal = readyTarih
            const data = {
                serviceName: 'dashBoard',
                methodName: 'dashboardTarih',
                tarih: readyTarih,
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
            }
            $.ajax({
                type: 'POST',
                url: store.state.POST_URL,
                data,
                success(res) {
                    if (res.status == 200) {
                        commit('setToken', res.userToken)
                        commit('setWorkDay', res.calismaGunu.tarih)
                        commit('setRoom', res)
                        commit('setParams', res.params)
                        commit('setAcenta', res.acentaList)
                        commit('setBildirim', res.bildirim)
                    }
                    return false
                },
                error(err) {
                    if (err.status == 503) {
                        router.push('/expariedLogin')
                    }
                },
                dataType: 'json',
            })
        },
        dateNext({
                     commit,
                     state,
                 }, authData) {
            commit('clearRoom')
            const today = new Date(state.workDay)
            const tarih = today.setDate(today.getDate() + 1)
            const gidenTarih = new Date(tarih).toLocaleDateString('en-US')
            const yeniDate = new Date(gidenTarih)
            const yil = yeniDate.getFullYear()
            const ay = String(yeniDate.getMonth() + 1)
                .padStart(2, '0')
            const gun = String(yeniDate.getDate())
                .padStart(2, '0')
            const readyTarih = [yil, ay, gun].join('-')
            store.state.dateDefaultGlobal = readyTarih
            const data = {
                serviceName: 'dashBoard',
                methodName: 'dashboardTarih',
                tarih: readyTarih,
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
            }
            $.ajax({
                type: 'POST',
                url: store.state.POST_URL,
                data,
                success(res) {
                    if (res.status == 200) {
                        commit('setToken', res.userToken)
                        commit('setWorkDay', res.calismaGunu.tarih)
                        commit('setRoom', res)
                        commit('setParams', res.params)
                        commit('setAcenta', res.acentaList)
                        commit('setBildirim', res.bildirim)
                    }
                    return false
                },
                error(err) {
                    if (err.status == 503) {
                        router.push('/expariedLogin')
                    }
                },
                dataType: 'json',
            })
        },
        bildirimCek({
                        commit,
                        state
                    }) {
            const data = {
                serviceName: 'dashBoard',
                crm_token: localStorage.getItem('otelUserToken'),
            }
            $.ajax({
                type: 'POST',
                url: store.state.POST_URL,
                data,
                success(res) {
                    commit('setBildirim', res.bildirim)
                },
                dataType: 'json',
            })
        },
        dashIn({
                   commit,
                   state
               }, authData) {
            if (authData == undefined || authData.clearRoom == true) {
                commit('clearRoom')
            }
            const data = {
                serviceName: 'dashBoard',
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
            }
            $.ajax({
                type: 'POST',
                url: store.state.POST_URL,
                data,
                success(res) {
                    commit('setWorkDay', res.calismaGunu)
                    commit('setRoom', res)
                    commit('setAcenta', res.acentaList)
                    commit('setUserData', jwt_decode(res.userToken))
                    commit('setBildirim', res.bildirim)
                    commit('setVardiya', res.vardiya)
                    commit('setTarife', res.params.tarifeList)
                    commit('setToken', res.userToken)
                },
                error(err) {
                    if (err.status == 503) {
                        router.push('/expariedLogin')
                    }
                },
                dataType: 'json',
            })
        },
        logIn({
                  commit,
                  state,
                  dispatch
              }, authData) {
            const data = {
                serviceName: 'account',
                methodName: 'logIn',
                modul: 'otel',
                user: authData.user,
                pwd: authData.pwd,
                firmaKodu: authData.firmaKodu,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (jwt_decode(res.userToken).defaultModul == 'otel') {
                            commit('setToken', res.userToken)
                            if (res.status == 204) {
                                resolve(res)
                                state.isVardiya = true
                                localStorage.setItem('otelIsVardiya', true)
                                commit('setToken', res.userToken)
                                router.push('/vardiyaKapali')
                            }
                            if (res.status == 200) {
                                dispatch('departman')
                                dispatch('iletisimFormu')
                                commit('setUserData', jwt_decode(res.userToken))
                                commit('setWorkDay', res.calismaGunu)
                                commit('setParams', res.params)
                                commit('setBildirim', res.bildirim)
                                commit('setVardiya', res.vardiya)
                                commit('setTarife', res.params.tarifeList)
                                resolve(res)
                            }
                        } else {
                            reject(res)
                        }
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        logOut({ commit }) {
            localStorage.removeItem('otelUserToken')
            commit('clearToken')
            commit('clearRoom')
            commit('clearUserData')
            commit('clearWorkDay')
            commit('clearIletisim')
            router.push('/login')
        },
        async initAuth({
                           commit,
                           state,
                           dispatch,
                       }) {
            const token = localStorage.getItem('otelUserToken')
            const data = {
                crm_token: token,
                serviceName: 'dashBoard',
            }
            if (token) {
                return new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'POST',
                        url: store.state.POST_URL,
                        data,
                        success(res) {
                            if (res.status == 204) {
                                commit('setToken', res.userToken)
                                state.isVardiya = true
                                localStorage.setItem('otelIsVardiya', true)
                                router.push('/vardiyaKapali')
                            }
                            if (res.status == 200) {
                                dispatch('departman')
                                dispatch('iletisimFormu')
                                resolve(res)
                                commit('setToken', res.userToken)
                                commit('setParams', res.params)
                                commit('setBildirim', res.bildirim)
                                commit('setUserData', jwt_decode(res.userToken))
                                commit('setWorkDay', res.calismaGunu)
                                commit('setVardiya', res.vardiya)
                                commit('setTarife', res.params.tarifeList)
                                if (router.history.current.name == 'vardiyaKapali') {
                                    router.push('/')
                                }
                            }
                            if (res.params) {
                                localStorage.setItem('otelListType', res.params.listType)
                                res.params.tema = res.params.tema == 1 ? 'dark' : 'light'
                                store.commit('appConfig/UPDATE_SKIN', res.params.tema)
                            }
                        },
                        error(err) {
                            if (err.status != 503) {
                                router.push('/login')
                            } else {
                                router.push('/expariedLogin')
                            }
                            reject(err)
                        },
                        dataType: 'json',
                    })
                })
            }
            router.push('/login')
        },
        justWorkDay({
                        commit,
                        state
                    }) {
            const data = {
                serviceName: 'dashBoard',
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setWorkDay', res.calismaGunu)
                        resolve(res.calismaGunu)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        vardiyaOpen({
                        commit,
                        state
                    }) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'dashBoard',
                methodName: 'vardiyaBaslat',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setToken', res.userToken)
                        state.isVardiya = false
                        localStorage.setItem('otelIsVardiya', false)
                        router.push('/')
                        window.location.reload()
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                        }
                    },
                    dataType: 'json',
                })
            })
        },
        vardiyaClosed({
                          commit,
                          state
                      }) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'dashBoard',
                methodName: 'vardiyaKapat',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.tokenStatus == 503) {
                            router.push('/expariedLogin')
                            return false
                        }
                        window.location.reload()
                        router.push('/login')
                        localStorage.setItem('otelIsVardiya', true)
                        localStorage.setItem('otelUserToken', '')
                        resolve(res)
                    },
                    error(err) {
                        if (err.status == 503) {
                            router.push('/expariedLogin')
                            localStorage.setItem('otelIsVardiya', false)
                        }
                        window.location.reload()
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
