import store from '@/store/index.js'

export default [
    {
        path: '/hizliRezervasyon',
        name: 'hizliRezervasyon',
        component: () => import('@/views/islemler/hizliRezervasyon.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Hızlı Rezervasyon',
            breadcrumb: [
                {
                    text: 'Hızlı Rezervasyon',
                },
            ],
        },
    },

]
