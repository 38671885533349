<template>
  <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view/>
    </component>
    <scroll-to-top v-if="enableScrollToTop"/>
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar, useMe } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    currentDate() {
      let today = new Date()
      const dd = String(today.getDate())
          .padStart(2, '0')
      const mm = String(today.getMonth() + 1)
          .padStart(2, '0')
      const yyyy = today.getFullYear()
      return (today = `${yyyy}-${mm}-${dd}`)
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      'primary',
      'secondary',
      'success',
      'info',
      'warning',
      'danger',
      'light',
      'dark',
    ]

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
          `--${colors[i]}`,
          document.documentElement,
      )
          .value
          .trim()
    }
    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
          useCssVar(
              `--breakpoint-${breakpoints[i]}`,
              document.documentElement,
          )
              .value
              .slice(0, -2),
      )
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const {
      skin,
      skinClasses
    } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  created() {
    this.$store.dispatch('bildirimCek')
    this.$store.dispatch('initAuth')
    localStorage.setItem('otelSuccessReturn', 'bottom-right')
    localStorage.getItem('otelSekme') == null ? localStorage.setItem('otelSekme', false) : ''
  },
}
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.b-avatar-group .b-avatar-group-inner {
  justify-content: center;
}

html {
  scroll-behavior: smooth !important;
}

/* .app-content {
  transform: translateX(-15px) !important;
} */
.carousel-control-prev {
  z-index: 500 !important;
}

.carousel-control-next {
  z-index: 500 !important;
}

.modalSelect .vs__selected {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  width: 174px !important;
  white-space: nowrap !important;
}

@media print {
  hr,
  .unPrint {
    display: none;
  }

  @page {
    margin: 5%;
  }

  button {
    display: none !important;
  }

  .card h4,
  .card h3 {
    white-space: nowrap !important;
  }
}

.spinners {
  position: absolute !important;
  margin-left: 47% !important;
  margin-top: 15% !important;
  width: 3rem !important;
  height: 3rem !important;
  z-index: 900 !important;
}

.card-header-row, .card-header-row-btn {
  width: 102% !important;
  position: absolute;
  margin: 0 -1.5% !important;
  border-radius: 6px;
}

.card-header-row h4 {
  transform: translate(0px, 3px);
}

.card-header-row-btn h4 {
  transform: translate(0px, 8px);
}

.header-row-date-btn {
  border-radius: 20px !important;
}

.search-bar {
  width: 30% !important;
}

</style>
