import store from '@/store'

export default {
    state: {
        rezervasyonNo: '',
        rezervasyonBilgileri: [],
        rezervasyonKonukListesi: [],
        rezervasyonOdaListesi: [],
        odaListesi: [],
        odaKodu: null,
    },
    mutations: {
        setToken(state, token) {
            if (token) {
                state.userToken = token
                localStorage.setItem('otelUserToken', token)
            }
        },
        setRezervasyonBilgileri(state, resertInfo) {
            state.odaKodu = resertInfo.odaKodu
            state.rezervasyonBilgileri = resertInfo
        },
        setrezervasyonKonukListesi(state, resertKonuk) {
            state.rezervasyonKonukListesi = resertKonuk
        },
        setRezervasyonOdaListesi(state, resertOda) {
            state.rezervasyonOdaListesi = resertOda
        },
        konukKontrolResult(state, result) {
            state.konukKontrolResult = result
        },
        setOdaListesi(state, odaList) {
            state.odaListesi = odaList
        },
        clearRezervasyon(state) {
            state.rezervasyonBilgileri = []
            state.rezervasyonKonukListesi = []
            state.rezervasyonOdaListesi = []
            state.odaListesi = []
        },
        clearYeniRez(state) {
            state.konukKontrolResult = []
        },
    },
    getters: {
        getRezList(state) {
            if (state.rezervasyonKonukListesi) {
                return state.rezervasyonKonukListesi
            }
        },
        rezOdaListReturn(state) {
            if (state.rezervasyonOdaListesi) {
                return state.rezervasyonOdaListesi
            }
        },
        konukKontrol(state) {
            if (state.konukKontrolResult) {
                return state.konukKontrolResult
            }
        },
        rezOdaListReturn(state) {
            if (state.odaListesi) {
                return state.odaListesi
            }
        },
    },
    actions: {
        guestSorgu({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'guestSorgu',
                term: authData.term,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        yeniRezSave({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'yeniRezervasyon',
                islem: 'confirm',
                odaKodu: authData.odaKodu,
                girisTarih: authData.girisTarih,
                cikisTarih: authData.cikisTarih,
                konukSayisi: authData.konukSayisi,
                odaKapatma: authData.odaKapatma == true ? 1 : false,
                konukSayisi: authData.aciklama,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data: authData,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        girisKontrol({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'yeniRezervasyon',
                islem: 'rezervasyonKontrol',
                odaKodu: authData.odaKodu,
                girisTarih: authData.girisTarih,
                cikisTarih: authData.cikisTarih,
                konukSayisi: authData.konukSayisi,
                odaKapatma: authData.odaKapatma == true ? 1 : 0,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('konukKontrolResult', res.result)
                            resolve(res)
                        } else {
                            commit('konukKontrolResult', res.result)
                            reject(res)
                        }
                    },
                    error(res) {
                        commit('konukKontrolResult', res.result)
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        odaListGet({
                       commit,
                       state
                   }) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        commit('setOdaListesi', res.data.odaListesi)
                        resolve(res)
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        backCheckOut({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'backCheckOut',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        onRezCheckIn({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'checkIn',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        iadeliCheckOut({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'checkOut',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
                iade: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        direkCheckOut({
                          commit,
                          state
                      }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'checkOut',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        odaMessage({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'notGuncelle',
                rezervasyonNo: authData.rezervasyonNo,
                aciklama: authData.aciklama || ' ', // todo BU BOSLUK SILINMEYECEK, KULLANICI VERI GIRMEZ ISE, BACK ENDE BOSLUK GIDECEK  'BACK END IN AYIBI DIYELIM.'
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('setToken', res.userToken)
                        resolve(res)
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        odaStatusChange({
                            commit,
                            state
                        }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                rezervasyonNo: authData.rezervasyonNo,
                odaKapatmaSet: authData.odaKapatmaSet == true ? 1 : 0,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        toplamFiyatUpdate({
                              commit,
                              state
                          }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'toplamUcretGuncelle',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
                toplamUcret: authData.toplamUcret,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        fiyatUpdate({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'fiyatGuncelle',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
                fiyat: authData.fiyat,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        cikisTarihUpdate({
                             commit,
                             state
                         }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
                islem: 'hguestCikisGuncelle',
                cikisTarih: authData.cikisTarih,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        girisTarihUpdate({
                             commit,
                             state
                         }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
                islem: 'hguestGirisGuncelle',
                girisTarih: authData.girisTarih,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        odaKapatma({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                rezervasyonNo: authData.rezervasyonNo,
                odaKapatmaSet: authData.value == true ? 1 : 0,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        yeniKonuk({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'konukKayit',
                rezervasyonNo: authData.rezervasyonNo,
                adi: authData.adi,
                soyAdi: authData.soyAdi,
                tcKimlik: authData.tcKimlik,
                telefon: authData.telefon,
                cinsiyet: authData.cinsiyet == 'Kadın' ? 2 : 1,
                onRezervasyon: authData.onRezervasyon == 'Normal' ? 0 : '' || authData.onRezervasyon == 'Rezervasyon' ? 1 : '',
                fiyat: authData.fiyat,
                girisTarih: authData.girisTarih,
                cikisTarih: authData.cikisTarih,
                tarife: authData.tarife,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        konukKayit({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'konukKayit',
                rezervasyonNo: authData.rezervasyonNo,
                adi: authData.adi,
                soyAdi: authData.soyAdi,
                tcKimlik: authData.tcKimlik,
                telefon: authData.telefon,
                cinsiyet: authData.cinsiyet == 'Kadın' ? 2 : 1,
                onRezervasyon: authData.onRezervasyon == 'Normal' ? 0 : '' || authData.onRezervasyon == 'Rezervasyon' ? 1 : '',
                fiyat: authData.fiyat,
                girisTarih: authData.girisTarih,
                cikisTarih: authData.cikisTarih,
                tarife: authData.tarife,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        kaydiSil({
                     commit,
                     state
                 }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'mukerrerKonaklama',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.hguestID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        tahsilatYapBaskaHesap({
                                  commit,
                                  state
                              }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'tahsilatYap',
                rezervasyonNo: authData.rezervasyonNo,
                odemeTuru: 'kredi',
                baskaHesap: true,
                tutar: authData.tutar,
                tcKimlik: authData.tcKimlik,
                fisNo: authData.fisNo,
                aciklama: authData.aciklama,
                hguestID: authData.hguestID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        tahsilatYap({
                        commit,
                        state,
                    }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'tahsilatYap',
                rezervasyonNo: authData.rezervasyonNo,
                odemeTuru: authData.odemeTuru,
                tutar: authData.tutar,
                fisNo: authData.fisNo,
                aciklama: authData.aciklama,
                hguestID: authData.hguestID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        mukerrerTahsilat({
                             commit,
                             state
                         }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'mukerrerTahsilat',
                rezervasyonNo: authData.rezervasyonNo,
                tahsilatID: authData.tahsilatID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        konukChange({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'konukDegistir',
                rezervasyonNo: authData.rezervasyonNo,
                adi: authData.adi,
                soyAdi: authData.soyAdi,
                tcKimlik: authData.tcKimlik,
                cinsiyet: authData.cinsiyet == 'Erkek' ? 1 : 2,
                tarife: authData.tarife,
                telefon: authData.telefon,
                otoPlaka: authData.otoPlaka,
                hguestID: authData.hguestID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        konukUpdate({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'konukGuncelle',
                rezervasyonNo: authData.rezervasyonNo,
                adi: authData.adi,
                soyAdi: authData.soyAdi,
                tcKimlik: authData.tcKimlik,
                cinsiyet: authData.cinsiyet == 'Erkek' ? 1 : 2,
                tarife: authData.tarife,
                telefon: authData.telefon,
                otoPlaka: authData.otoPlaka,
                hguestID: authData.hguestID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        odaChange({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'odaDegis',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.chooseRezervasyon.hguestID,
                odaKodu: authData.odaSelect.odaKodu,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                            commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi,)
                            commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi,)
                            commit('setBildirim', res.bildirim)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        dragOdaChange({
                          commit,
                          state
                      }, authData) {
            const data = {
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                islem: 'odaDegis',
                rezervasyonNo: authData.rezervasyonNo,
                hguestID: authData.guestID,
                odaKodu: authData.odaKodu,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('setToken', res.userToken)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        async rezervasyonGo({
                                commit,
                                state
                            }, authData) {
            const data = {
                serviceName: 'rezervasyon',
                methodName: 'rezervasyonDetay',
                crm_token: state.userToken || localStorage.getItem('otelUserToken'),
                rezervasyonNo: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('setRezervasyonBilgileri', res.data.rezervasyonBilgileri)
                        commit('setrezervasyonKonukListesi', res.data.rezervasyonKonukListesi)
                        commit('setOdaListesi', res.data.odaListesi)
                        commit('setRezervasyonOdaListesi', res.data.rezervasyonOdaListesi)
                        commit('setToken', res.userToken)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
}
