import store from '@/store/index.js'

export default [
    {
        path: '/odaYonetimi',
        name: 'odaYonetimi',
        component: () => import('@/views/ayarlar/odaYonetimi.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Oda Yönetimi',
            breadcrumb: [
                {
                    text: 'Oda Yönetimi',
                },
            ],
        },
    },
    {
        path: '/odaTurleri',
        name: 'odaTurleri',
        component: () => import('@/views/ayarlar/odaTurleri.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Oda Türleri',
            breadcrumb: [
                {
                    text: 'Oda Türleri',
                },
            ],
        },
    },
    {
        path: '/kullaniciYonetimi',
        name: 'kullaniciYonetimi',
        component: () => import('@/views/ayarlar/kullaniciYonetimi.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Kullanıcı Yönetimi',
            breadcrumb: [
                {
                    text: 'Kullanıcı Yönetimi',
                },
            ],
        },
    },
    {
        path: '/departman',
        name: 'departman',
        component: () => import('@/views/ayarlar/departman.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Departman',
            breadcrumb: [
                {
                    text: 'Departman',
                },
            ],
        },
    },
    {
        path: '/acenta',
        name: 'acenta',
        component: () => import('@/views/ayarlar/acenta.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Acenta',
            breadcrumb: [
                {
                    text: 'Acenta',
                },
            ],
        },
    },
    {
        path: '/tarife',
        name: 'tarifeacenta',
        component: () => import('@/views/ayarlar/tarife.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Tarife',
            breadcrumb: [
                {
                    text: 'Tarife',
                },
            ],
        },
    },
    {
        path: '/odaYonetimTur',
        name: 'odaYonetimTur',
        component: () => import('@/views/ayarlar/odaYonetimTur.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Oda Tür Yönetimi',
            breadcrumb: [
                {
                    text: 'Tarife',
                },
            ],
        },
    },
]
