import store from '@/store/index.js'

export default [
    {
        path: '/sistemAyarlari',
        name: 'sistemAyarlari',
        component: () => import('@/views/webPanel/sistemAyar.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Sistem Ayarları',
            breadcrumb: [
                {
                    text: 'Sistem Ayarları',
                },
            ],
        },
    },
    {
        path: '/iletisimFormu',
        name: 'iletisimFormu',
        component: () => import('@/views/webPanel/iletisimFormu.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'İletişim Formu',
            breadcrumb: [
                {
                    text: 'İletişim Formu',
                },
            ],
        },
    },
    {
        path: '/dosyaYoneticisi',
        name: 'dosyaYoneticisi',
        component: () => import('@/views/webPanel/dosyaYoneticisi.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Dosya Yöneticisi',
            breadcrumb: [
                {
                    text: 'Dosya Yöneticisi',
                },
            ],
        },
    },
    {
        path: '/dinamikSayfalar',
        name: 'dinamikSayfalar',
        component: () => import('@/views/webPanel/dinamikSayfa.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Dinamik Sayfalar',
            breadcrumb: [
                {
                    text: 'Dosya Yöneticisi',
                },
            ],
        },
    },
    {
        path: '/galeriYonetimi',
        name: 'galeriYonetimi',
        component: () => import('@/views/webPanel/galeriYonetim.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Galeri Yönetimi',
            breadcrumb: [
                {
                    text: 'Galeri',
                },
            ],
        },
    },
    {
        path: '/duyuruKategorileri',
        name: 'duyuruKategorileri',
        component: () => import('@/views/webPanel/duyuruKategori.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Duyuru Kategorileri',
            breadcrumb: [
                {
                    text: 'Duyuru Kategorileri',
                },
            ],
        },
    },
    {
        path: '/sliderYonetimi',
        name: 'sliderYonetimi',
        component: () => import('@/views/webPanel/slider.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Slider Yönetimi',
            breadcrumb: [
                {
                    text: 'Slider Yönetimi',
                },
            ],
        },
    },
    {
        path: '/blokYonetimi',
        name: 'blokYonetimi',
        component: () => import('@/views/webPanel/blok.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Blok Yönetimi',
            breadcrumb: [
                {
                    text: 'Blok Yönetimi',
                },
            ],
        },
    },
    {
        path: '/birimler',
        name: 'birimler',
        component: () => import('@/views/webPanel/birimler.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Personel ve birim yönetimi',
            breadcrumb: [
                {
                    text: 'Birim Yönetimi',
                },
            ],
        },
    },
    {
        path: '/odalar',
        name: 'odalar',
        component: () => import('@/views/webPanel/odalar.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Odalar',
            breadcrumb: [
                {
                    text: 'Oda Yönetimi',
                },
            ],
        },
    },
    {
        path: '/odaTurleri',
        name: 'odaTurleri',
        component: () => import('@/views/webPanel/odaTur.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Oda Türleri',
            breadcrumb: [
                {
                    text: 'Oda Türleri',
                },
            ],
        },
    },
    {
        path: '/odaUpdate/:id',
        name: 'oda',
        component: () => import('@/views/webPanel/odaUpdate.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Oda Güncelle',
            breadcrumb: [
                {
                    text: 'Oda Güncelle',
                },
            ],
        },
    },
    {
        path: '/odaOzelFiyat/:id',
        name: 'oda',
        component: () => import('@/views/webPanel/odaFiyat.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Oda Özel Fiyat',
            breadcrumb: [
                {
                    text: 'Oda Özel Fiyat',
                },
            ],
        },
    },
    {
        path: '/odaKayit',
        name: 'oda',
        component: () => import('@/views/webPanel/odaKayit.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Oda Kaydı',
            breadcrumb: [
                {
                    text: 'Oda Kaydı',
                },
            ],
        },
    },
    {
        path: '/odaResim/:id',
        name: 'oda',
        component: () => import('@/views/webPanel/odaResimler.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Oda Resimleri',
            breadcrumb: [
                {
                    text: 'Oda Resimleri',
                },
            ],
        },
    },
    {
        path: '/menu',
        name: 'menuYonetimi',
        component: () => import('@/views/webPanel/menu.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Menü Yönetimi',
            breadcrumb: [
                {
                    text: 'Menü Yönetimi',
                },
            ],
        },
    },
    {
        path: '/altMenu/:id',
        name: 'oda',
        component: () => import('@/views/webPanel/altMenu.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Alt Menü Yönetimi',
            breadcrumb: [
                {
                    text: 'Alt Menü Yönetimi',
                },
            ],
        },
    },
    {
        path: '/rezKapaliGunler',
        name: 'oda',
        component: () => import('@/views/webPanel/rezKapali.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Rezervasyona Kapalı Günler',
            breadcrumb: [
                {
                    text: 'Rezervasyona Kapalı Günler',
                },
            ],
        },
    },
]
