import store from '@/store/index.js'

export default [
    {
        path: '/bekleyenTahsilatlar',
        name: 'bekleyenTahsilatlar',
        component: () => import('@/views/raporlar/bekleyenTah.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Bekleyen Tahsilatlar',
            breadcrumb: [
                {
                    text: 'Bekleyen Tahsilatlar',
                },
            ],
        },
    },
    {
        path: '/siparisDetay/:id',
        name: 'siparisDetay',
        component: () => import('@/views/raporlar/siparisDetay.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Sipariş Detayı',
            breadcrumb: [
                {
                    text: 'Sipariş Detayı',
                },
            ],
        },
    },
    {
        path: '/konaklamaListesi',
        name: 'konaklamaListesi',
        component: () => import('@/views/raporlar/konaklamaList.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Konaklama Listesi',
            breadcrumb: [
                {
                    text: 'Konaklama Listesi',
                },
            ],
        },
    },
    {
        path: '/checkOutListesi',
        name: 'checkOutListesi',
        component: () => import('@/views/raporlar/checkOutList.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Check Out Listesi',
            breadcrumb: [
                {
                    text: 'Check Out Listesi',
                },
            ],
        },
    },
    {
        path: '/housekeepingListesi',
        name: 'housekeepingListesi',
        component: () => import('@/views/raporlar/houseKeeping.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'House Keeping Listesi',
            breadcrumb: [
                {
                    text: 'House Keeping Listesi',
                },
            ],
        },
    },
    {
        path: '/rezervasyonListesi',
        name: 'rezervasyonListesi',
        component: () => import('@/views/raporlar/rezervasyonList.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Rezervasyon Listesi',
            breadcrumb: [
                {
                    text: 'Rezervasyon Listesi',
                },
            ],
        },
    },
    {
        path: '/onlineRezervasyonListesi',
        name: 'onlineRezervasyonListesi',
        component: () => import('@/views/raporlar/onlineRezList.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Online Rezervasyon Listesi',
            breadcrumb: [
                {
                    text: 'Online Rezervasyon Listesi',
                },
            ],
        },
    },
    {
        path: '/aylikRapor',
        name: 'aylikRapor',
        component: () => import('@/views/raporlar/aylikRapor.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Aylık Rapor',
            breadcrumb: [
                {
                    text: 'Aylık Rapor',
                },
            ],
        },
    },
    {
        path: '/yillikRapor',
        name: 'yillikRapor',
        component: () => import('@/views/raporlar/yillikRapor.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Yıllık Rapor',
            breadcrumb: [
                {
                    text: 'Yıllık Rapor',
                },
            ],
        },
    },
    {
        path: '/odaRapor',
        name: 'odaRapor',
        component: () => import('@/views/raporlar/odaRapor.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Oda Raporu',
            breadcrumb: [
                {
                    text: 'Oda Raporu',
                },
            ],
        },
    },
    {
        path: '/bekleyenTahsilatlar',
        name: 'bekleyenTahsilatlar',
        component: () => import('@/views/raporlar/konaklamaGecmis.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Bekleyen Tahsilatlar',
            breadcrumb: [
                {
                    text: 'Bekleyen Tahsilatlar',
                },
            ],
        },
    },
    {
        path: '/vardiyaOzeti/:id',
        name: 'vardiyaOzeti',
        component: () => import('@/views/raporlar/vardiyaOzeti.vue'),
        props: true,
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Vardiya Özeti',
            breadcrumb: [
                {
                    text: 'Vardiya Özeti',
                },
            ],
        },
    },
    {
        path: '/vardiyaListesi',
        name: 'vardiyaListesi',
        component: () => import('@/views/raporlar/vardiyaList.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Vardiya Kayıtları',
            breadcrumb: [
                {
                    text: 'Vardiya Kayıtları',
                },
            ],
        },
    },
    {
        path: '/detayliGelirListesi/:id',
        name: 'detayliGelirListesi',
        component: () => import('@/views/raporlar/detayliGelirList.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Detaylı Gelir Listesi',
            breadcrumb: [
                {
                    text: 'Detaylı Gelir Listesi',
                },
            ],
        },
    },
    {
        path: '/konaklamaKayitlari/:id',
        name: 'konaklamaKayitlari',
        component: () => import('@/views/raporlar/konaklamaKayitlari.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Konaklama Kayıtları',
            breadcrumb: [
                {
                    text: 'Konaklama Kayıtları',
                },
            ],
        },
    },
    {
        path: '/giderKayitlari/:id',
        name: 'giderKayitlari',
        component: () => import('@/views/raporlar/giderKayitlari.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Gider Kayıtları',
            breadcrumb: [
                {
                    text: 'Gider Kayıtları',
                },
            ],
        },
    },
    {
        path: '/sanalPos&FaturaKayitlari',
        name: 'sanalPos&FaturaKayitlari',
        component: () => import('@/views/raporlar/sanalPos&Fatura.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Sanal Pos ve Fatura Kayıtları',
            breadcrumb: [
                {
                    text: 'Sanal Pos ve Fatura Kayıtları',
                },
            ],
        },
    },
    {
        path: '/bankaPos',
        name: 'bankaPos',
        component: () => import('@/views/raporlar/bankaPos.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Banka Pos',
            breadcrumb: [
                {
                    text: 'Sanal Pos',
                },
            ],
        },
    },
    {
        path: '/raporGunDetay',
        name: 'raporGunDetay',
        component: () => import('@/views/raporlar/raporGunDetay.vue'),
        props: true,
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Rapor Gün Detayı',
            breadcrumb: [
                {
                    text: 'Sanal Pos',
                },
            ],
        },
    },
    {
        path: '/kasaRaporu',
        name: 'kasaRaporu',
        component: () => import('@/views/raporlar/kasaRaporu.vue'),
        props: true,
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Kasa Raporu',
            breadcrumb: [
                {
                    text: 'Kasa Raporu',
                },
            ],
        },
    },
]
