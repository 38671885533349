import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
    isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import dashboard from './routes/dashboard'
import auth from './routes/auth'
import raporlar from './routes/raporlar'
import webPanel from './routes/webPanel'
import islemler from './routes/islemler'
import ayarlar from './routes/ayarlar'
import cari from './routes/cari'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    base: '/',
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
    routes: [{
        path: '/',
        redirect: { name: 'dashboard-ecommerce' },
    }, ...auth, ...dashboard, ...raporlar, ...webPanel, ...islemler, ...ayarlar, ...cari, {
        path: '*',
        redirect: 'error-404',
    }],
})

router.beforeEach(async (to, from, next) => {
    const isLoggedIn = store.getters.isAuthenticated
    const data = {
        text: to.meta.pageTitle,
        link: to.fullPath,
    }
    if (data.text) {
        store.commit('setBreadCrump', data)
    }
    if (to.path === '/vardiyaKapali') {
        if (store.getters.vardiya.length === 0) {
            return next()
        }
        return next(false)
    }
    if (to.path === '/login' || to.path === '/expariedLogin') {
        if (isLoggedIn) {
            return next(false)
        }
        return next()
    }
    if (!isLoggedIn) {
        try {
            await store.dispatch('initAuth')
            return next()
        } catch (error) {
            return next({ name: 'login' })
        }
    }
    return next()
})

// ? For splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
