import axios from 'axios'
import store from '@/store'

export default {
    state: {
        temaListesi: [],
        sistemAyar: [],
        iletisim: [],
        dosyaYoneticisi: [],
        dinamikSayfalar: [{}],
        gallery: [{}],
        duyuru: [{}],
        galleryDetails: [],
        pageGaleriDetay: [],
        duyuruDetay: [],
        kategoriDetay: [],
        duyuruEditContent: [],
        duyuruResim: [],
        duyuruDosya: [],
        slider: [],
        blok: [],
        blokEdit: [],
        birimler: [],
        servisler: [],
        odalar: [],
        odaTurleri: [],
        emptyOda: [],
        tarifeListesi: [],
        doluOda: [],
        odaOzelFiyat: [],
        odaResimler: [],
        menu: [],
        altMenu: [],
        offlineRez: [],
        odaYonetimTur: [],
        ePostaList: [],
    },
    mutations: {
        setEPostaList(state, list) {
            state.ePostaList = list
        }, //! !!! ODA YONETİM TUR
        odaYonetimTurSave(state, list) {
            state.odaYonetimTur = list.odaTurleri
        },
        celarOdaYonetimTurSave(state, list) {
            state.odaYonetimTur = []
        }, //! REZ KAPALI GUNLER
        offlineRezSave(state, list) {
            state.offlineRez = list
        },
        clearOfflineRez(state) {
            state.offlineRez = []
        }, //! ALT MENU YONETİMİ
        altMenuSave(state, list) {
            state.altMenu = list
        },
        clearAltMenu(state) {
            state.altMenu = []
        }, //! MENU YONETİMİ
        menuSave(state, list) {
            state.menu = list
        },
        clearMenu(state) {
            state.menu = []
        }, //! ODA RESİMLER
        odaResimlerSave(state, list) {
            state.odaResimler = list
        },
        clearOdaResimler(state) {
            state.odaResimler = []
        }, //! ODA ÖZEL FİYAT
        ozelFiyatSave(state, list) {
            state.odaOzelFiyat = list
        },
        clearOdaOzelFiyat(state) {
            state.odaOzelFiyat = []
        }, //! ODA UPDATE
        doluOdaSave(state, list) {
            state.doluOda = list
        },
        clearDoluOda(state) {
            state.doluOda = []
        }, //! ODA KAYIT
        emptyOdaSave(state, list) {
            state.emptyOda = list
        },
        tarifeListesiSave(state, list) {
            state.tarifeListesi = list
        },
        clearEmptyOda(state) {
            state.emptyOda = []
        }, //! Oda Türleri
        odaTurSave(state, list) {
            state.odaTurleri = list.liste
        },
        clearOdaTurleri(state) {
            state.odaTurleri = []
        }, //! Odalar
        odalarSave(state, list) {
            state.odalar = list.liste
        },
        clearOdalar(state) {
            state.odalar = []
        }, //! Servisler
        servisSave(state, list) {
            state.servisler = list.liste
        },
        clearServis(state) {
            state.servisler = []
        }, //! Birimler
        birimSave(state, list) {
            state.birimler = list.liste
        },
        clearBirim(state) {
            state.birimler = []
        }, //  ! -------- Blok
        blokSave(state, list) {
            state.blok = list.liste
        },
        editBlokSave(state, list) {
            state.blokEdit = list.edit
        },
        clearEditBlok(state) {
            state.blokEdit = []
        },
        clearBlok(state) {
            state.blok = []
        }, // !  -------- Slider
        sliderSave(state, list) {
            state.slider = list
        },
        clearSlider(state, list) {
            state.slider = list
        }, //  ! -------- Ayar
        sistemAyarSave(state, list) {
            state.sistemAyar = list
        },
        temaListSave(state, list) {
            state.temaListesi = list
        },
        clearSistemAyarSave(state) {
            state.sistemAyar = []
        }, //!  -------- Sayfa
        clearDinamikSayfalar(state) {
            state.dinamikSayfalar = []
        },
        dinamikSayfalarSave(state, list) {
            state.dinamikSayfalar = list.liste
        },
        pageGaleriDetaySave(state, list) {
            state.pageGaleriDetay = list
        },
        clearPageGaleriDetay(state) {
            state.pageGaleriDetay = []
        }, //! -------- Iletisim
        clearIletisim(state) {
            state.iletisim = []
        },
        iletisimSave(state, list) {
            state.iletisim = list
        }, // !-------- Dosya
        clearDosyaYoneticisi(state) {
            state.sistemYoneticisi = []
        },
        dosyaYoneticisiSave(state, list) {
            state.dosyaYoneticisi = list.liste
        }, //! -------- Galeri
        clearGallery(state) {
            state.gallery = []
        },
        galleryDetailsSave(state, list) {
            state.galleryDetails = list
        },
        gallerySave(state, list) {
            state.gallery = list.liste
        },
        clearGalleryDetails(state) {
            state.galleryDetails = []
        }, // !  --------- Duyuru
        clearDuyuru(state) {
            state.duyuru = []
        },
        duyuruSave(state, list) {
            state.duyuru = list.liste
        },
        duyuruResimSave(state, list) {
            state.duyuruResim = list
        },
        duyuruDosyaSave(state, list) {
            state.duyuruDosya = list
        },
        duyuruEditContentSave(state, list) {
            state.duyuruEditContent = list.edit
        },
        kategoriDetaySave(state, list) {
            state.kategoriDetay = list
        },
        clearDuyuruDetay(state) {
            state.galleryDetails = []
        },
        clearDuyuruResim(state) {
            state.duyuruResim = []
        },
        clearDuyuruDosya(state) {
            state.duyuruResim = []
        },
        clearDuyuruEditContent(state) {
            state.duyuruEditContent = []
        },
    },
    actions: {

        //! ! ODA YONETİM TUR
        odaYonetimTur({
                          commit,
                          state
                      }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaTurleri',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('odaYonetimTurSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaYonetimTurRemove({
                                commit,
                                state
                            }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaTurleri',
                odaTurSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('odaYonetimTurSave', res)
                        resolve(res)
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        odaYonetimTurSave({
                              commit,
                              state
                          }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaTurleri',
                adi: authData.isim,
                tip: authData.tip,
                islem: 'turKayit',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('odaYonetimTurSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaYonetimTurUpdate({
                                commit,
                                state
                            }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'otel',
                methodName: 'odaTurleri',
                islem: 'edit',
                adi: authData.isim,
                tip: authData.tip,
                tipID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('odaYonetimTurSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, //! REZ KAPALI GUNLER
        offlineRez({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'offlineCizelge',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('offlineRezSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        ofRezSave({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'offlineCizelge',
                baslangic: authData.baslangic,
                bitis: authData.bitis,
                mesaj: authData.mesaj,
                cizelgeKayit: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('offlineRezSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        ofRezRemove({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'offlineCizelge',
                cizelgeSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('offlineRezSave', res)
                    },
                    dataType: 'json',
                })
            })
        }, //! MENU YONETİMİ
        menu({
                 commit,
                 state
             }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'menuYonetimi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('menuSave', res)
                    },
                    dataType: 'json',
                })
            })
        },
        altMenu({
                    commit,
                    state
                }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'altMenuYonetimi',
                ustMenuID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('altMenuSave', res)
                    },
                    dataType: 'json',
                })
            })
        },
        altMenuSave({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'altMenuYonetimi',
                altMenuKaydi: true,
                ustMenuID: authData.ustMenuID,
                yazi: authData.yazi,
                modul: authData.modul,
                menuTuru: authData.menuTuru,
                hedef: authData.hedef,
                link: authData.link,
                sira: authData.sira,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('altMenuSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        altMenuSil({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'altMenuYonetimi',
                ustMenuID: authData.id,
                menuSil: authData.sil,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('altMenuSave', res)
                    },
                    dataType: 'json',
                })
            })
        },
        menuSiraUpdate({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'menuYonetimi',
                siraGuncelle: authData.id,
                sira: authData.sira,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('menuSave', res)
                    },
                    dataType: 'json',
                })
            })
        },
        altMenuSiraUpdate({
                              commit,
                              state
                          }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'altMenuYonetimi',
                ustMenuID: authData.ustMenuID,
                siraGuncelle: authData.id,
                sira: authData.sira,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('altMenuSave', res)
                    },
                    dataType: 'json',
                })
            })
        },
        menuSave({
                     commit,
                     state
                 }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'menuYonetimi',
                anaMenuKaydi: true,
                yazi: authData.yazi,
                menuTuru: authData.menuTuru,
                hedef: authData.hedef,
                link: authData.link,
                sira: authData.sira,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('menuSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        menuSil({
                    commit,
                    state
                }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'menuYonetimi',
                anaMenuSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('menuSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, //! ODA RESİMLER
        odaResimler({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaResimleri',
                odaID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('odaResimlerSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaResimRemove({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaResimleri',
                Sil: authData.id,
                odaID: authData.odaID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('odaResimlerSave', res)
                        resolve(res)
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        }, //! ODA ÖZEL FİYAT
        odaOzelFiyat({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaOzelFiyat',
                odaID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('ozelFiyatSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, //! Oda
        emptyOda({
                     commit,
                     state
                 }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaDuzenle',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('emptyOdaSave', res)
                        commit('tarifeListesiSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        doluOda({
                    commit,
                    state
                }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaDuzenle',
                Edit: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('emptyOdaSave', res)
                        commit('tarifeListesiSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, //! Oda Türleri
        odaTur({
                   commit,
                   state
               }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaTip',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('odaTurSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaTurRemove({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaTip',
                odaTipSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('odaTurSave', res)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        odaTurSave({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaTip',
                isim: authData.isim,
                tip: authData.tip,
                odaTipKaydi: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('odaTurSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaTurUpdate({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaTip',
                isim: authData.isim,
                tip: authData.tip,
                odaTipDuzenle: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('odaTurSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, //! Odalar
        oda({
                commit,
                state
            }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('odalarSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaSilWebPanel({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaListesi',
                odaSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('odalarSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaPaylasimGuncelle({
                                commit,
                                state
                            }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaListesi',
                odaPaylasimGuncelle: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('odalarSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        odaKapatmaGuncelle({
                               commit,
                               state
                           }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'odaListesi',
                odaKapatma: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('odalarSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, //! Servisler
        servis({
                   commit,
                   state
               }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'servisListesi',
                birimID: authData.birimID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('servisSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        userRemove({
                       dispatch,
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'servisListesi',
                birimID: authData.birimID,
                personelSil: authData.personelSil,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            dispatch('servis', { birimID: authData.birimID })
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        servisSave({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'servisListesi',
                birimID: authData.birimID,
                altBirimAdi: authData.altBirimAdi,
                servisKaydi: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('servisSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        servisRemove({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'servisListesi',
                birimID: authData.birimID,
                servisSil: authData.servisID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('servisSave', res)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        }, //! Birimler
        birim({
                  commit,
                  state
              }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'birimListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('birimSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        birimSave({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'birimListesi',
                birimAdi: authData.birimAdi,
                birimKaydi: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('birimSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        birimRemove({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'birimListesi',
                birimSil: authData.birimID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            resolve(res)
                            commit('birimSave', res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        }, //! ------------- Blok
        blok({
                 commit,
                 state
             }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'blokYonetimi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('blokSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        blokSil({
                    commit,
                    state
                }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'blokYonetimi',
                blokSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('blokSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        blokEdit({
                     commit,
                     state
                 }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'blokYonetimi',
                Edit: authData.blokID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('editBlokSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, //! ------------- Slider
        slider({
                   commit,
                   state
               }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'sliderYonetimi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('sliderSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        sliderSil({
                      commit,
                      state
                  }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'sliderYonetimi',
                sliderSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('sliderSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, // !------------- Duyuru
        duyuruKategorisiEkle({
                                 commit,
                                 state
                             }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruKategorileri',
                kategoriKaydi: true,
                baslik: authData.baslik,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        duyuruKategoriSil({
                              commit,
                              state
                          }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruKategorileri',
                kategoriSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        duyuru({
                   commit,
                   state
               }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruKategorileri',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kategoriSave({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruKategorileri',
                baslik: authData.baslik,
                kategoriKaydi: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kategoriRemove({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruKategorileri',
                kategoriSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kategoriUpdate({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruKategorileri',
                baslik: authData.baslik,
                kategoriDuzenle: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kategoriDetay({
                          commit,
                          state
                      }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruListesi',
                kategoriID: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('kategoriDetaySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        kategoriDetayRemove({
                                commit,
                                state
                            }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruListesi',
                kategoriID: authData.kategoriID,
                duyuruSil: authData.id,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('kategoriDetaySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        duyuruSave({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruListesi',
                kategoriID: authData.kategoriID,
                baslik: authData.baslik,
                icerik: authData.icerik,
                duyuruKaydi: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('kategoriDetaySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        duyuruDurumUpdate({
                              commit,
                              state
                          }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruListesi',
                kategoriID: authData.kategoriID,
                durum: authData.duyuruID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('kategoriDetaySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        duyuruEdit({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruListesi',
                kategoriID: state.kategoriDetay.kategori.kategoriID,
                Edit: authData.edit,
            }
            $.ajax({
                type: 'POST',
                url: store.state.POST_URL,
                data,
                success(res) {
                    commit('duyuruEditContentSave', res)
                },
                dataType: 'json',
            })
        },
        duyuruEditSave({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruListesi',
                kategoriID: state.kategoriDetay.kategori.kategoriID,
                Edit: authData.edit,
                duyuruDuzenle: authData.duyuruDuzenle,
                baslik: state.duyuruEditContent.baslik,
                icerik: state.duyuruEditContent.icerik,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('kategoriDetaySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        duyuruResim({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruGaleri',
                duyuruID: authData.duyuruID,
            }
            $.ajax({
                type: 'POST',
                url: store.state.POST_URL,
                data,
                success(res) {
                    commit('duyuruResimSave', res)
                },
                dataType: 'json',
            })
        },
        resimRemove({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruGaleri',
                duyuruID: authData.duyuruID,
                Sil: authData.Sil,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruResimSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        duyuruDosyaRemove({
                              commit,
                              state
                          }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruDosya',
                duyuruID: authData.duyuruID,
                Sil: authData.Sil,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruDosyaSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        duyuruDosya({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'duyuruDosya',
                duyuruID: authData.duyuruID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('duyuruDosyaSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, // !---------- Galeri
        galleryDetails({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'galeriDetay',
                galeriID: authData.galeriID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('galleryDetailsSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        galleryDetailsRemove({
                                 commit,
                                 state
                             }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'galeriDetay',
                galeriID: authData.galeriID,
                Sil: authData.sil,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('galleryDetailsSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        galleryUpdate({
                          commit,
                          state
                      }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'galeriYonetimi',
                galeriAdi: authData.galeriAdi,
                galeriDuzenle: authData.galeriID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('gallerySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        galleryRemove({
                          commit,
                          state
                      }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'galeriYonetimi',
                galeriSil: authData.galeriID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('gallerySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        gallerySave({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'galeriYonetimi',
                galeriAdi: authData.galleryName,
                galeriKayit: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('gallerySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        gallery({
                    commit,
                    state
                }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'galeriYonetimi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('gallerySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        dosyaYoneticisi({
                            commit,
                            state
                        }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'galeriYonetimi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('gallerySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, // ! --------- Sayfalar
        pageSave({
                     commit,
                     state
                 }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'dinamikSayfalar',
                sayfaAdi: authData.sayfaAdi,
                hizmet: authData.hizmet,
                icerik: authData.icerik,
                sayfaKaydi: true,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('dinamikSayfalarSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        pageRemove({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'dinamikSayfalar',
                sayfaSil: authData.sayfaSil,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('dinamikSayfalarSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        pageUpdate({
                       commit,
                       state
                   }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'dinamikSayfalar',
                Edit: authData.pageID,
                sayfaAdi: authData.sayfaAdi,
                hizmet: authData.hizmet,
                icerik: authData.icerik,
                sayfaDuzenle: authData.pageID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('dinamikSayfalarSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        pageGaleriDetay({
                            commit,
                            state
                        }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'sayfaResimleri',
                dinamikID: authData.dinamikID,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('pageGaleriDetaySave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        pageGalleryDetailsRemove({
                                     commit,
                                     state
                                 }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'sayfaResimleri',
                dinamikID: authData.dinamikID,
                Sil: authData.sil,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('pageGaleriDetaySave', res)
                    },
                    dataType: 'json',
                })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }, // ! -------- Dosya
        dosyaSave({
                      commit,
                      state
                  }, authData) {
            const fd = new FormData()
            fd.append('dosya', authData.veri)
            fd.append('serviceName', 'web')
            fd.append('methodName', 'dosyaYoneticisi')
            fd.append('aciklama', authData.aciklama)
            fd.append('dosyaKaydi', true)
            fd.append('crm_token', localStorage.getItem('otelUserToken'))
            return new Promise((resolve, reject) => {
                axios
                    .post(store.state.POST_URL, fd, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        dosyaRemove({
                        commit,
                        state
                    }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'dosyaYoneticisi',
                Sil: authData.sil,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('dosyaYoneticisiSave', res)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        async dinamikSayfalar({
                                  commit,
                                  state
                              }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'dinamikSayfalar',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('dinamikSayfalarSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        dosyaYoneticisi({
                            commit,
                            state
                        }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'dosyaYoneticisi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('dosyaYoneticisiSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, // ! -------- İletişim
        iletisimRemove({
                           commit,
                           state
                       }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'iletisimFormu',
                Sil: authData,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('iletisimSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        iletisimFormu({
                          commit,
                          state
                      }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'iletisimFormu',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('iletisimSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        }, // ! -------- Sistem Ayar
        sistemAyar({
                       commit,
                       state
                   }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'webAyar',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('sistemAyarSave', res.ayarlar)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        temaList({
                     commit,
                     state
                 }) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'web',
                methodName: 'temaListesi',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('temaListSave', res.temaListesi)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
    getters: {
        GET_EPOSTA_LIST(state) {
            if (state.ePostaList) {
                return state.ePostaList
            }
        }, //! ! Sistem Ayarları Tema Listesi
        GET_TEMA_LISTESI(state) {
            if (state.temaListesi) {
                return state.temaListesi
            }
        }, //! ! ODA YONETİM TUR
        odaYonetimTurReturn(state) {
            if (state.odaYonetimTur) {
                return state.odaYonetimTur
            }
        }, //! REZ KAPALI GUNLER
        ofRezReturn(state) {
            if (state.offlineRez) {
                return state.offlineRez
            }
        }, //! MENU YONETİMİ
        menuReturn(state) {
            if (state.menu) {
                return state.menu
            }
        },
        altMenuReturn(state) {
            if (state.altMenu) {
                return state.altMenu
            }
        }, //! WEB AYAR
        webAyar(state) {
            return state.sistemAyar
        }, //! ODA RESİMLER
        odaResimler(state) {
            return state.odaResimler
        }, //! Oda
        tarifeListesiReturn(state) {
            if (state.tarifeListesi) {
                return state.tarifeListesi
            }
        }, //! Oda Türleri
        odaTurleriReturn(state) {
            if (state.odaTurleri) {
                return state.odaTurleri
            }
        }, //! Odalar
        odaListesiReturn(state) {
            if (state.odalar) {
                return state.odalar
            }
        }, //! Servisler
        servisListesiReturn(state) {
            if (state.servisler) {
                return state.servisler
            }
        }, //! Birimler
        birimListesiReturn(state) {
            if (state.birimler) {
                return state.birimler
            }
        }, //!
        pageGaleriDetayReturn(state) {
            return state.pageGaleriDetay
        },
        sliderList(state) {
            if (state.slider) {
                if (state.slider.liste) {
                    return state.slider.liste
                }
            }
        },
        getIletisim(state) {
            return state.iletisim
        },
    },
}
