import router from '@/router'
import store from '@/store'

export default {
  state: {
  },
  actions: {
    acenta({ commit, state, dispatch }, authData) {
      const data = {
        serviceName: 'dashBoard',
        methodName: 'odaStatus',
        key: 'acenta',
        value: authData[2] == 'temizle' ? '' : authData[1].acentaID,
        odaKodu: authData[0],
        crm_token: localStorage.getItem('otelUserToken'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.status == 200) {
              resolve(res)
              dispatch('dashIn')
            }
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    odaDurum({ commit, state, dispatch }, authData) {
      const data = {
        serviceName: 'dashBoard',
        methodName: 'odaStatus',
        key: 'status',
        value: authData[1][0],
        odaKodu: authData[0],
        crm_token: localStorage.getItem('otelUserToken'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.status == 200) {
              resolve(res)
              dispatch('dashIn')
            }
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    temizOda({ commit, state, dispatch }, authData) {
      const data = {
        methodName: 'odaStatus',
        key: 'status',
        value: 0,
        odaKodu: authData,
        crm_token: localStorage.getItem('otelUserToken'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.status == 200) {
              dispatch('dashIn')
              resolve(res)
            }
            return false
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    gelirKayit({ commit, state, dispatch }, authData) {
      const data = {
        methodName: 'gelirKayit',
        serviceName: 'dashBoard',
        odemeTuru: authData.odemeSelected.id,
        departmanID: authData.departmanSelected.departmanID,
        tutar: authData.tutar,
        fisNo: authData.fisNo,
        isim: authData.isim,
        soyIsim: authData.soyIsim,
        aciklama: authData.aciklama,
        crm_token: localStorage.getItem('otelUserToken'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.result.status == 200) {
              resolve(res)
              dispatch('vardiyaOzeti', { vardiyaID: 0 })
            }
            reject(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
    giderKayit({ commit, state, dispatch }, authData) {
      const data = {
        methodName: 'giderKayit',
        serviceName: 'dashBoard',
        tutar: authData.tutar,
        fisNo: authData.fisNo,
        aciklama: authData.aciklama,
        crm_token: localStorage.getItem('otelUserToken'),
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            if (res.result.status == 200) {
              resolve(res)
              dispatch('vardiyaOzeti', { vardiyaID: 0 })
            }
            reject(res)
          },
          error(res) {
            reject(res)
          },
          dataType: 'json',
        })
      })
    },
    vardiyaGelirKayit({ commit, state, dispatch }, authData) {
      const data = {
        crm_token: localStorage.getItem('otelUserToken'),
        serviceName: 'vardiya',
        methodName: 'vardiyaOzeti',
        islem: 'gelirKayit',
        vardiyaID: authData.vardiyaID,
        odemeTuru: authData.odemeSelected.id,
        departmanID: authData.departmanSelected.departmanID,
        tutar: authData.tutar,
        fisNo: authData.fisNo,
        isim: authData.isim,
        soyIsim: authData.soyIsim,
        aciklama: authData.aciklama,
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'POST',
          url: store.state.POST_URL,
          data,
          success(res) {
            dispatch('vardiyaOzeti', { vardiyaID: authData.vardiyaID })
            resolve(res)
          },
          error(err) {
            reject(err)
          },
          dataType: 'json',
        })
      })
    },
  },
}
