export default [
    {
        path: '/',
        name: 'dashboard-ecommerce',
        component: () => import('@/views/dashboard/dash.vue'),
        meta: {
            pageTitle: 'Dashboard',
            breadcrumb: [
                {
                    text: 'Konaklamalar',
                },
            ],
        },
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
    },
    {
        path: '/rezervasyon/:id',
        name: 'rezervasyon',

        component: () => import('@/views/rezervasyon/rezervasyon.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Rezervasyon Detayı',
            breadcrumb: [
                {
                    text: 'Rezervasyon Detay',
                },
            ],
        },
    },
    {
        path: '/yeniRezervasyon/:id',
        name: 'yeniRezervasyon',

        component: () => import('@/views/rezervasyon/yeniRezervasyon.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Yeni Rezervasyon',
            breadcrumb: [
                {
                    text: 'Yeni Rezervasyon',
                },
            ],
        },
    },
]
