import Vue from 'vue'
import Vuex from 'vuex'
import store from '@/store'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import auth from './auth'
import savePost from './savePost'
import more from './more'
import rezervasyon from './rezervasyon'
import raporlar from './raporlar'
import webPanel from './webPanel'
import islemler from './islemler'
import ayarlar from './ayarlar'
import cari from './cari'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        POST_URL: 'https://api.oys.web.tr/webservis.php',
        breadcrump: [],
        isSekme: localStorage.getItem('otelSekme') == 'true',
        API_URL: 'https://api.oys.web.tr/', // todo BURASI DEGISECEK  (Sadece resimler icin)
        // API_URL: 'https://api.oys.web.tr/oys-api/', // todo BURASI DEGISECEK  (Sadece resimler icin)
        dateDefaultGlobal: null,
    },
    getters: {
        getBreadCrump(state) {
            return state.breadcrump
        },
    },
    mutations: {
        setBreadCrump(state, data) {
            const exists = state.breadcrump.find(x => x.link === data.link)
            if (!exists) {
                if (data.link.includes('/rezervasyon')) {
                    setTimeout(() => {
                        const { odaKodu } = store.state.rezervasyon
                        if (odaKodu) {
                            state.breadcrump.push({
                                text: `${data.text} (${odaKodu})`,
                                link: data.link,
                            })
                        } else {
                            setTimeout(() => {
                                const sonOdaKodu = store.state.rezervasyon.odaKodu
                                if (sonOdaKodu) {
                                    state.breadcrump.push({
                                        text: `${data.text} (${odaKodu})`,
                                        link: data.link,
                                    })
                                }
                            }, 1000)
                        }
                    }, 1000)
                } else {
                    state.breadcrump.push(data)
                }
                if (state.breadcrump.length > 6) {
                    state.breadcrump.splice(0, state.breadcrump.length - 6)
                }
            }
        },
    },
    modules: {
        app,
        appConfig,
        verticalMenu,
        auth,
        savePost,
        more,
        rezervasyon,
        raporlar,
        webPanel,
        islemler,
        ayarlar,
        cari,
    },
    strict: process.env.DEV,
})
