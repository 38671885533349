import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import Print from 'vue-print-nb'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// Axios Mock Adapter

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(Print)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.directive('numericOnly', {
    bind(el) {
        el.addEventListener('keypress', (event) => {
            if ((event.keyCode < 48 || event.keyCode > 57) && event.keyCode !== 44 && event.keyCode !== 46) {
                event.preventDefault()
            }
        })
        el.addEventListener('input', () => {
            let oldVal = el.value
            let regex = /^\d*[,.]?\d*$/
            if (!regex.test(oldVal)) {
                el.value = oldVal.slice(0, -1)
            }
            if ((oldVal.match(/\./g) || []).length > 1) {
                el.value = oldVal.slice(0, -1)
            }
            let newVal = oldVal.replace(',', '.')
            if (newVal !== oldVal) {
                let caretPosition = el.selectionStart
                el.value = newVal
                el.setSelectionRange(caretPosition - 1, caretPosition - 1)
            }
        })
    },
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
