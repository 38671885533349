import store from '@/store'

export default {
    state: {
        hizliRez: [],
    },
    mutations: {
        hizliRezSave(state, list) {
            state.hizliRez = list
        },
        clearHizliRez(state) {
            state.hizliRez = []
        },
    },
    actions: {
        yeniHizliRezSave({
                             commit,
                             state
                         }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'hizliRezervasyon',
                islem: 'yeniRezervasyon',
                adi: authData.adi,
                soyAdi: authData.soyAdi,
                tcKimlik: authData.tcKimlik,
                telefon: authData.telefon,
                odaKapatma: authData.odaKapatma,
                cinsiyet: authData.cinsiyet,
                tarife: authData.tarife,
                fiyat: authData.fiyat,
                aciklama: authData.aciklama,
            }
            authData.list.forEach(element => {
                data[element] = 1
            })
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        if (res.result.status == 200) {
                            commit('hizliRezSave', res)
                            resolve(res)
                        } else {
                            reject(res)
                        }
                    },
                    error(res) {
                        reject(res)
                    },
                    dataType: 'json',
                })
            })
        },
        hizliRezYear({
                         commit,
                         state
                     }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'hizliRezervasyon',
                yil: authData.year,
                ay: authData.month,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('hizliRezSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        hizliRezMonth({
                          commit,
                          state
                      }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'hizliRezervasyon',
                yil: authData.year,
                ay: authData.month == 'Ocak' ? '01' : '' || authData.month == 'Şubat' ? '02' : '' || authData.month == 'Mart' ? '03' : '' || authData.month == 'Nisan' ? '04' : '' || authData.month == 'Mayıs' ? '05' : '' || authData.month == 'Mayıs' ? '05' : '' || authData.month == 'Haziran' ? '06' : '' || authData.month == 'Temmuz' ? '07' : '' || authData.month == 'Ağustos' ? '08' : '' || authData.month == 'Eylül' ? '09' : '' || authData.month == 'Ekim' ? '10' : '' || authData.month == 'Kasım' ? '11' : '' || authData.month == 'Aralık' ? '12' : '',
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        commit('hizliRezSave', res)
                        resolve(res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
        hizliRez({
                     commit,
                     state
                 }, authData) {
            const data = {
                crm_token: localStorage.getItem('otelUserToken'),
                serviceName: 'rezervasyon',
                methodName: 'hizliRezervasyon',
                yil: authData.year,
                ay: authData.month,
            }
            return new Promise((resolve, reject) => {
                $.ajax({
                    type: 'POST',
                    url: store.state.POST_URL,
                    data,
                    success(res) {
                        resolve(res)
                        commit('hizliRezSave', res)
                    },
                    error(err) {
                        reject(err)
                    },
                    dataType: 'json',
                })
            })
        },
    },
    getters: {
        // ----------  Toplu Rezervasyon
        odalar(state) {
            if (state.hizliRez.odaListe) {
                return state.hizliRez.odaListe
            }
        },
        konuklar(state) {
            if (state.hizliRez.konukListe) {
                return state.hizliRez.konukListe
            }
        },
        ay(state) {
            if (state.hizliRez.rapor) {
                return state.hizliRez.rapor.ay == '01' ? 'Ocak' : '' || state.hizliRez.rapor.ay == '02' ? 'Şubat' : '' || state.hizliRez.rapor.ay == '03' ? 'Mart' : '' || state.hizliRez.rapor.ay == '04' ? 'Nisan' : '' || state.hizliRez.rapor.ay == '05' ? 'Mayıs' : '' || state.hizliRez.rapor.ay == '06' ? 'Haziran' : '' || state.hizliRez.rapor.ay == '07' ? 'Temmuz' : '' || state.hizliRez.rapor.ay == '08' ? 'Ağustos' : '' || state.hizliRez.rapor.ay == '09' ? 'Eylül' : '' || state.hizliRez.rapor.ay == '10' ? 'Ekim' : '' || state.hizliRez.rapor.ay == '11' ? 'Kasım' : '' || state.hizliRez.rapor.ay == '12' ? 'Aralık' : ''
            }
        },
        yil(state) {
            if (state.hizliRez.rapor) {
                return state.hizliRez.rapor.yil
            }
        },
        aySonuRez(state) {
            if (state.hizliRez.rapor) {
                const arr = Array.from({ length: state.hizliRez.rapor.aySonu }, (_, index) => index + 1,)
                return arr
            }
        },
    },
}
