import store from '@/store/index.js'

export default [
    {
        path: '/faturaKayitlari',
        name: 'faturaKayitlari',
        component: () => import('@/views/cari/faturaList.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Fatura Kayıtları',
            breadcrumb: [
                {
                    text: 'Fatura Listesi',
                },
            ],
        },
    },
    {
        path: '/cariListe',
        name: 'cariListe',
        component: () => import('@/views/cari/cariList.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
        },
        meta: {
            pageTitle: 'Cari Kartlar',
            breadcrumb: [
                {
                    text: 'Cari Katlar',
                },
            ],
        },
    },
    {
        path: '/cariDetay/:id',
        name: 'cariDetay',
        component: () => import('@/views/cari/cariDetay.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Cari Detay',
            breadcrumb: [
                {
                    text: 'Cari Detay',
                },
            ],
        },
    },
    {
        path: '/cariEkstre/:id',
        name: 'cariDetay',
        component: () => import('@/views/cari/cariEkstre.vue'),
        beforeEnter(to, from, next) {
            if (localStorage.getItem('otelUserToken')) {
                next()
            } else {
                next('/login')
            }
            // if (store.getters.isAuthenticated) {
            //   next()
            // } else {
            //   next('/login')
            // }
        },
        meta: {
            pageTitle: 'Cari Ekstre',
            breadcrumb: [
                {
                    text: 'Cari Detay',
                },
            ],
        },
    },
]
