export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/expariedLogin',
    name: 'Hızlı Login',
    component: () => import('@/views/auth/expariedToken.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/vardiyaKapali',
    name: 'vardiyaKapali',
    component: () => import('@/views/auth/kapaliVardiya.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
    beforeEnter(to, from, next) {
      if (localStorage.getItem('otelIsVardiya') != false) {
        next()
      } else {
        next('/')
      }
    },
  },
]
